<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="true"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
        >
            <ExtendedFilterPanel
                v-if="$helpers.isExtendedFilterPanelVisible(extendedFilter)"
                v-bind:value.sync="extendedFilter"
            />
        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

        <IncomingExFilterDlg
            v-if="collection === 'Incomings' || collection === 'Dirords'"
            v-model="extendedFilter"
            ref="IncomingExFilterDlgRef"
        />

        <OutgoingExFilterDlg
            v-if="collection === 'Outgoings'"
            v-model="extendedFilter"
            ref="OutgoingExFilterDlgRef"
        />

        <InnerExFilterDlg
            v-if="collection === 'Inners' || collection === 'Ords'"
            v-model="extendedFilter"
            ref="InnerExFilterDlgRef"
        />

    </div>
</template>

<script>
import _ from "lodash";
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';

import IncomingExFilterDlg from '@/components/documents/dialogs/extended-filters/Incomings.vue';
import OutgoingExFilterDlg from '@/components/documents/dialogs/extended-filters/Outgoings.vue';
import InnerExFilterDlg from '@/components/documents/dialogs/extended-filters/Inners.vue';

import Incomings from "./nested/Incomings.vue";
import Outgoings from "./nested/Outgoings.vue";
import Inners from "./nested/Inners.vue";
import Dirords from "./nested/Dirords.vue";
import Ords from "./nested/Ords.vue";

export default {
    name: "Documents",
    props: {
        year: {
            type: String,
            default: null
        }
    },
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,
        ExtendedFilterPanel,
        IncomingExFilterDlg,
        OutgoingExFilterDlg,
        InnerExFilterDlg,
        Incomings,
        Outgoings,
        Inners,
        Dirords,
        Ords
    },
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['archive/lists/documents/getCollection']
            },
            set: function(newValue) {
                this.$store.commit('archive/lists/documents/SET_COLLECTION', newValue);
            }
        },
        status() {
            return this.$store.getters['archive/lists/documents/getStatus'];
        },
        search: {
            get: function() {
                return this.$store.getters['archive/lists/documents/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }                
                this.$store.commit('archive/lists/documents/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        extendedFilter: {
            get: function() {
                return this.$store.getters['archive/lists/documents/getExtendedFilter'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }   
                if (newValue)
                    this.$store.commit('archive/lists/documents/PUSH_EXTENDED_FILTER', { collection: this.collection, value: newValue } );
            }
        }
    },
    data() {
        return {
            dataSource: true,
            filterDataSource: null,
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            let method = this[btn.Action];

            if (method)
                method(btn);
            else
                this.$notify.alert(`Действие ${btn.Action} не реализовано`);
        },
        async onFilterClick() {
            try
            {
                switch(this.collection)
                {
                    case "Incomings":
                    case "Dirords":
                        await this.$refs.IncomingExFilterDlgRef.open();
                        break;

                    case "Outgoings":
                        await this.$refs.OutgoingExFilterDlgRef.open();
                        break;

                    case "Inners":
                    case "Ords":
                        await this.$refs.InnerExFilterDlgRef.open();
                        break;

                    default: break;
                }
            }
            catch (ex)
            {
                if (ex.isCancelled === true)
                    console.warn("Подготовка расширенного фильтра отменена");
            }
        },
        async loadButtons() {
            this.setOverlayVisible({ visible: true });

            let response = await httpAPI({
                url: `api/actions/collection?type=Documents.Correspondence`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.Payload;
                this.$store.commit('documents/toolbar/SET_MENU', { menu: this.dataSource.Data.Menu });
            }

            this.setOverlayVisible({ visible: false });
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `api/archive/documents-filter?collection=${this.collection}&year=${this.status}`,
                method: 'GET'
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['global/auth/getUserInfo'])?.Permissions ?? [];
                filterResponse.data.Payload.Filter.Items = filterResponse.data.Payload.Filter.Items.filter(i => this.$_.difference(i.Requires, permissions).length === 0);
                this.filterDataSource = filterResponse.data.Payload;
            }
        },
        async Export() {
            let filter = this.$refs.currentComponent.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;
            let exportURI = `api/download/export?param=${JSON.stringify(filter)}`;

            let docTypeLabel = "";

            switch(this.collection)
            {
                case "Incomings":
                    docTypeLabel = " (Входящие)";
                    break;

                case "Dirords":
                    docTypeLabel = " (ДИРОРД)";
                    break;

                case "Outgoings":
                    docTypeLabel = " (Исходящие)";
                    break;

                case "Inners":
                    docTypeLabel = " (Внутренние)";
                    break;

                case "Ords":
                    docTypeLabel = " (ОРД)";
                    break;

                default: break;
            }

            this.$eventBus.$emit('on-get-report', { url: exportURI, text: `Экспорт списка документов${docTypeLabel} от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'Xls' });
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        },
    },
    watch: {
        collection() {
            this.loadButtons();
        }
    },
    async beforeRouteEnter(to, from, next) {
        next(async vm => {
            vm.$store.commit('archive/lists/documents/PUSH_STATUS', to.params.year);
            await vm.loadButtons();
        });
    },
    async beforeRouteUpdate(to, from, next) {        
        this.$store.commit('archive/lists/documents/PUSH_STATUS', to.params.year);
        await this.loadButtons();
        next();
    }
}
</script>